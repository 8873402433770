import cn from 'classnames';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { PWA_URL } from 'core-lib/data/common';
import FacebookSVG from 'src/common/icons/facebook.svg';
import InstagramSVG from 'src/common/icons/instagram.svg';
import LinkedInSVG from 'src/common/icons/linkedin.svg';
import ParkplusSVG from 'src/common/icons/parkplus-website-logo.svg';
import TwitterSVG from 'src/common/icons/twitter.svg';
import YoutubeSVG from 'src/common/icons/youtube.svg';
import { SOURCE_TYPE } from 'src/components/common/headerv1/headerv1';
import {
  APP_LINK_FOOTER,
  FACEBOOK_LINK,
  INSTAGRAM_LINK,
  LINKEDIN_LINK,
  TWITTER_LINK,
  YOUTUBE_LINK,
} from 'src/data/parkplus_socials';
import Location1 from 'ui/components/newIcons/Location1';
import Message1 from 'ui/components/newIcons/Message1';
import s from './footer.module.scss';

export const SOCIAL_LINKS = [
  {
    id: 1,
    icon: <InstagramSVG />,
    url: INSTAGRAM_LINK,
    label: 'Instagram Handle',
  },
  {
    id: 2,
    icon: <FacebookSVG />,
    url: FACEBOOK_LINK,
    label: 'Facebook Handle',
  },

  {
    id: 3,
    icon: <TwitterSVG />,
    url: TWITTER_LINK,
    label: 'Twitter Handle',
  },
  {
    id: 4,
    icon: <YoutubeSVG />,
    url: YOUTUBE_LINK,
    label: 'Youtube Handle',
  },
  {
    id: 5,
    icon: <LinkedInSVG />,
    url: LINKEDIN_LINK,
    label: 'LinkedIn Handle',
  },
];

const SERVICES = [
  {
    id: 21,
    url: '/new-cars',
    label: 'Cars',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 23,
    url: '/e-challan',
    label: 'Challan Information',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 24,
    url: '/fastag',
    label: 'FASTag',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 25,
    url: '/parking',
    label: 'Parking Solutions',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 26,
    url: '/fuel-price',
    label: 'Fuel Price',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 26,
    url: '/rto',
    label: 'RTO',
    sourceType: SOURCE_TYPE.internal,
  },
];

const QUICK_LINKS = [
  {
    id: 11,
    url: '/contact-us',
    label: 'Contact Us',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 12,
    url: '/blog',
    label: 'Blogs',
    sourceType: SOURCE_TYPE.internal,
  },
  // {
  //   id: 16,
  //   url: '/cst',
  //   label: 'Help & Support',
  //   sourceType: SOURCE_TYPE.external,
  // },
  {
    id: 15,
    url: '/f/sbi-fastag-recharge',
    label: 'SBI FASTag Recharge',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 14,
    url: '/c/telangana-challan-information',
    label: 'Telangana Challan',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 17,
    url: '/blog/discover/event-management-system',
    label: 'Tech Blogs',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 18,
    url: '/business/valet-service',
    label: 'Valet Services',
    sourceType: SOURCE_TYPE.internal,
  },
  {
    id: 19,
    url: '/bug-bounty',
    label: 'Bug bounty',
    sourceType: SOURCE_TYPE.internal,
  },
];

export const Footer = () => (
  <footer className={s.root}>
    <div className={s.new_image}>
      <h3 className={s.title}>Download Park+ app</h3>
      <p className={s.subtitle}>
        Stay on the top of your car game with Park+. Sit back and relax while we
        take care of your car-related needs, all in one place.
      </p>

      <div className={s.special}>
        <a
          rel="noreferrer"
          href={APP_LINK_FOOTER}
          target="_blank"
          className={s.appLink}
        >
          <div className={s.app_icon}>
            <Image
              loading="lazy"
              layout="fill"
              alt="google play"
              src="https://strapi-file-uploads.s3.ap-south-1.amazonaws.com/Google_Play_Badge_001eb0f465.png"
              objectFit="contain"
            />
          </div>
        </a>
        <a
          rel="noreferrer"
          href={APP_LINK_FOOTER}
          target="_blank"
          className={s.appLink}
        >
          <div className={s.app_icon}>
            <Image
              layout="fill"
              alt="app store"
              src="https://strapi-file-uploads.s3.ap-south-1.amazonaws.com/App_Store_Badge_f39b9659af.png"
              objectFit="contain"
            />
          </div>
        </a>
      </div>
    </div>
    <div className={cn(s.container, 'cmn_container_no_padding')}>
      <div className={s.header}>
        <div className={s.top}>
          <ParkplusSVG className={s.logo} />{' '}
        </div>

        <div className={s.socials}>
          {SOCIAL_LINKS.map((link) => (
            <a
              href={link.url}
              key={`${link.id}_social_link`}
              className={s.links}
              target="_blank"
              rel="noreferrer"
              aria-label={link?.label}
            >
              {link.icon}
            </a>
          ))}
        </div>

        <div className={s.appLinks}>
          <a
            rel="noreferrer"
            href={APP_LINK_FOOTER}
            target="_blank"
            className={s.appLink}
          >
            <div className={s.app_icon}>
              <Image
                layout="fill"
                alt="google play"
                src="https://strapi-file-uploads.s3.ap-south-1.amazonaws.com/Google_Play_Badge_001eb0f465.png"
                objectFit="contain"
              />
            </div>
          </a>
          <a
            rel="noreferrer"
            href={APP_LINK_FOOTER}
            target="_blank"
            className={s.appLink}
          >
            <div className={s.app_icon}>
              <Image
                layout="fill"
                alt="app store"
                src="https://strapi-file-uploads.s3.ap-south-1.amazonaws.com/App_Store_Badge_f39b9659af.png"
                objectFit="contain"
              />
            </div>
          </a>
        </div>
      </div>

      <div className={s.interlinking}>
        <div className={s.link_container}>
          <h3 className={s.link_title}>Quick Links</h3>
          {QUICK_LINKS.map((link) => {
            if (link.sourceType === SOURCE_TYPE.internal) {
              return (
                <Link
                  href={link.url}
                  prefetch={false}
                  key={`${link.id}_quick_link`}
                  className={s.link_label}
                >
                  {link.label} {/* link.isSpecial && link.icon */}
                </Link>
              );
            }
            return (
              <Link
                href={`${PWA_URL}${link.url}`}
                prefetch={false}
                key={`${link.id}_quick_link_2`}
                className={s.link_label}
              >
                {link.label} {/* link.isSpecial && link.icon */}
              </Link>
            );
          })}
        </div>
        <div className={s.link_container}>
          <h3 className={s.link_title}>Products</h3>
          {SERVICES.map((link) => {
            if (link.sourceType === SOURCE_TYPE.internal) {
              return (
                <Link
                  href={link.url}
                  key={`${link.id}_services`}
                  prefetch={false}
                  className={s.link_label}
                >
                  {link.label}
                </Link>
              );
            }
            return (
              <Link
                href={`${PWA_URL}${link.url}`}
                prefetch={false}
                key={`${link.id}_service_2`}
                className={s.link_label}
              >
                {link.label} {/* link.isSpecial && link.icon */}
              </Link>
            );
          })}
        </div>
      </div>
      <div className={s.contact}>
        <h3 className={s.contact_title}>Reach us</h3>
        <div className={s.contact_link}>
          <a href="mailto:support@myparkplus.com" className={s.link_text}>
            <Message1 height={20} width={20} />
            <span>support@myparkplus.com</span>
          </a>
        </div>
        <div>
          <span className={s.link_label}>For business enquiries : </span>
          <div className={s.contact_link}>
            <a href="mailto:sales@myparkplus.com">
              <Message1 height={20} width={20} />
              <span className={s.link_text}>sales@myparkplus.com</span>
            </a>
          </div>
        </div>

        <div className={s.contact_link}>
          <div className={s.lgo}>
            <Location1 height={20} width={20} />
          </div>
          <a
            href="https://maps.app.goo.gl/65CoadB3crHgwrde6"
            target="_blank"
            rel="noreferrer"
            className={s.address}
          >
            Unitech Cyber Park, 5th Floor, Tower A, Sec-39, Gurugram, Haryana
            122022
          </a>
        </div>
      </div>
    </div>
    <div className={s.bottom}>
      <div className={cn(s.bottom_content, 'cmn_container_no_padding')}>
        <h3 className={s.bottom_heading}>© 2020 Park+. All rights reserved</h3>
        <div className={s.link_wrapper}>
          <div className={s.bottom_links}>
            <a href="/terms-conditions" className={s.bottom_links}>
              Terms & Conditions
            </a>{' '}
            |{' '}
            <a href="/privacy-policy" className={s.bottom_links}>
              Privacy Policy
            </a>{' '}
          </div>
        </div>
      </div>
    </div>
  </footer>
);
